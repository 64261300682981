<template>
  <div v-if="Object.keys(orderDetailsData).length != 0" class="profile mb-4">
    <div class="container">
      <b-row>
        <b-col md="6">
          <div class="text-left mb-3">
            <h5>Order Details</h5>
          </div>
          <div class="d-flex profile-maindv mb-4">
            <div class="profile-detail mr-2 ml-2">
              <h6 class="mb-3">Delivery Address</h6>
              <b-media tag="li">
                <template #aside>Name:</template>
                <p class="mb-0">
                  {{
                    orderDetailsData &&
                    orderDetailsData.deliveryAddress &&
                    orderDetailsData.deliveryAddress.address
                      ? orderDetailsData.deliveryAddress.address.fullName
                      : ""
                  }}
                </p>
              </b-media>
              <p class="mb-2">
                <b-icon icon="telephone-fill" aria-label="Help"></b-icon>
                +91
                {{ getContactDetails("PRIMARY_PHONE") }}
              </p>
              <p class="mb-2">
                <b-icon icon="envelope-fill" aria-label="Help"></b-icon>
                {{ getContactDetails("PRIMARY_EMAIL") }}
              </p>
              <p class="mb-2">
                <b-icon
                  icon="geo-alt-fill"
                  variant="success"
                  aria-label="Help"
                ></b-icon>
                {{
                  orderDetailsData &&
                  orderDetailsData.deliveryAddress &&
                  orderDetailsData.deliveryAddress.address
                    ? orderDetailsData.deliveryAddress.address.houseNumber
                    : ""
                }},
                {{
                  orderDetailsData &&
                  orderDetailsData.deliveryAddress &&
                  orderDetailsData.deliveryAddress.address
                    ? orderDetailsData.deliveryAddress.address.street2
                    : ""
                }},
                {{
                  orderDetailsData &&
                  orderDetailsData.deliveryAddress &&
                  orderDetailsData.deliveryAddress.address
                    ? orderDetailsData.deliveryAddress.address.city
                    : ""
                }},
                {{
                  orderDetailsData &&
                  orderDetailsData.deliveryAddress &&
                  orderDetailsData.deliveryAddress.address
                    ? orderDetailsData.deliveryAddress.address.state
                    : ""
                }},
                {{
                  orderDetailsData &&
                  orderDetailsData.deliveryAddress &&
                  orderDetailsData.deliveryAddress.address
                    ? orderDetailsData.deliveryAddress.address.country
                    : ""
                }},
                {{
                  orderDetailsData &&
                  orderDetailsData.deliveryAddress &&
                  orderDetailsData.deliveryAddress.address
                    ? orderDetailsData.deliveryAddress.address.zip
                    : ""
                }}
              </p>
            </div>
          </div>
          <div class="d-flex profile-maindv">
            <div class="profile-detail mr-2 ml-2">
              <h6 class="mb-3">Order Details</h6>
              <ul class="list-unstyled od-ulli mb-0">
                <b-media tag="li">
                  <template #aside>Provider:</template>
                  <div>
                    <p class="mb-0 font-weight-bold">{{ providerName }}</p>
                  </div>
                </b-media>
                <b-media tag="li">
                  <template #aside>Order Number:</template>
                  <p class="mb-0 font-weight-bold">
                    {{ orderDetailsData ? orderDetailsData.orderId : "" }}
                  </p>
                </b-media>
                <b-media tag="li">
                  <template #aside>Order Created Date:</template>
                  <p class="mb-0 font-weight-bold">
                    <!-- 05/02/2021 01:55 PM -->
                    {{ createdDate }}
                  </p>
                </b-media>
                <b-media tag="li">
                  <template #aside>Amount:</template>
                  <p class="mb-0 font-weight-bold">
                    &#x20B9;
                    {{ orderDetailsData ? orderDetailsData.orderAmount : "" }}

                    <!-- {{ orderDetailData ? orderAmount : "" }}
                    {{ orderDetailData ? orderCurrency : "" }}-->
                  </p>
                </b-media>
                <b-media tag="li">
                  <template #aside>Payment Mode:</template>
                  <p class="mb-0 font-weight-bold">
                    {{ orderDetailsData ? orderDetailsData.paymentType : "" }}
                  </p>
                </b-media>
                <b-media tag="li">
                  <template #aside>Update Order Status:</template>
                  <p class="mb-0 font-weight-bold">
                    {{ orderDetailsData ? orderDetailsData.reportStatus : "" }}
                  </p>
                </b-media>
              </ul>
            </div>
          </div>
        </b-col>
        <b-col md="6">
          <div class="text-left mb-3">
            <h5>Track Order</h5>
          </div>
          <div
            v-if="orderDetailsData.orderStatus !== 'Cancelled'"
            class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between"
          >
            <div
              :class="`step ${
                orderStatus(orderDetailsData.orderStatus) >= 0
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <b-icon icon="check" aria-label="check"></b-icon>
                </div>
              </div>
              <h4 class="step-title">Order</h4>
            </div>
            <div
              :class="`step ${
                orderStatus(orderDetailsData.orderStatus) >= 1
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <b-icon icon="gear" aria-label="gear"></b-icon>
                </div>
              </div>
              <h4 class="step-title">Under Process</h4>
            </div>
            <div
              :class="`step ${
                orderStatus(orderDetailsData.orderStatus) >= 2
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <b-icon icon="truck" aria-label="truck"></b-icon>
                </div>
              </div>
              <h4 class="step-title">Shipped</h4>
            </div>
            <div
              :class="`step ${
                orderStatus(orderDetailsData.orderStatus) >= 3
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <b-icon icon="house" aria-label="house"></b-icon>
                </div>
              </div>
              <h4 class="step-title">Delivered</h4>
            </div>
          </div>

          <div
            v-else
            class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between"
          >
            <div
              :class="`step ${
                orderStatus(orderDetailsData.orderStatus) >= 0
                  ? 'completed'
                  : ''
              }`"
            >
              <div class="step-icon-wrap">
                <div class="step-icon">
                  <b-icon icon="check" aria-label="check"></b-icon>
                </div>
              </div>
              <h4 class="step-title">Order</h4>
            </div>
            <div
              :class="`step ${
                orderStatus(orderDetailsData.orderStatus) >= 1
                  ? 'Cancelled'
                  : ''
              }`"
            >
              <div class="step-icon-wrap">
                <div class="step-icon cancelbg">
                  <b-icon
                    icon="x-circle"
                    class="text-white"
                    aria-label="x-circle"
                  ></b-icon>
                </div>
              </div>
              <h4 class="step-title">Cancelled</h4>
            </div>
          </div>

          <div class="order-maindiv">
            <div class="d-flex justify-content-between mb-4">
              <div>
                <h6>#{{ orderDetailsData ? orderDetailsData.orderId : "" }}</h6>
                <div class="d-flex">
                  <b-link
                    href="#"
                    v-bind:class="
                      orderDetailsData.orderStatus === 'Cancelled'
                        ? 'cancelled-button'
                        : 'xsbutton' ||
                          orderDetailsData.orderStatus === 'Created'
                        ? 'status-xsbutton'
                        : 'xsbutton'
                    "
                    >{{
                      orderDetailsData ? orderDetailsData.orderStatus : ""
                    }}</b-link
                  >
                  <!-- <p v-bind:style="{ color: '#07ac04' }" class="m-0 ml-3">
                    Arriving By 15 May
                  </p>-->
                </div>
              </div>
              <div>
                <p v-bind:style="{ color: '#58585b' }" class="m-0">
                  Order Total ({{
                    Array.isArray(orderDetailsData.items) &&
                    orderDetailsData.items.length
                  }}
                  item)
                </p>
                <h4>
                  &#x20B9;
                  {{ orderDetailsData ? orderDetailsData.orderAmount : "" }}
                </h4>
              </div>
            </div>
            <ul
              v-for="(item, index) in orderDetailsData.items"
              :key="index"
              class="list-unstyled"
            >
              <template v-if="$options.components['items-order-item']">
                <items-order-item :itemData="item" />
              </template>
            </ul>
          </div>
          <div class="primary-btn text-right">
            <b-button
              v-if="
                orderDetailsData.orderStatus === 'Processing' ||
                orderDetailsData.orderStatus === 'Dispatched' ||
                orderDetailsData.orderStatus === 'Delivered' ||
                orderDetailsData.orderStatus === 'Cancelled'
                  ? true
                  : false
              "
              variant="primary"
              class="mr-3"
              @click="orderAgain"
              >Order Again</b-button
            >
            <b-button
              v-else
              variant="primary"
              class="mr-3"
              @click="cancelOrder(orderDetailsData.orderId)"
              >Cancel Order</b-button
            >
            <b-button
              variant="outline-primary"
              @click="needHelpClick(orderDetailsData.orderId)"
              >Need Help</b-button
            >
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
export default {
  name: "order-detail",
  computed: {
    ...mapState({
      orderDetailsData: (state) => state.profile.orderDetailsData,
      cancelled: (state) => state.profile.cancelled,
      cartData: (state) => state.checkout.cartData,
    }),
    providerName() {
      const name = Array.from(
        new Set(this.orderDetailsData.items.map((each) => each.providerName))
      );
      return name[0];
    },
    createdDate() {
      return moment(this.orderDetailsData.createdAt).format(
        "MMMM Do YYYY, hh:mm a"
      );
    },
  },
  props: {
    cartItem: {
      default: () => {},
      type: Object,
    },
    callCheckout: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (Object.keys(this.orderDetailsData).length == 0) {
      this.$router.push("/my-order");
    }
  },
  methods: {
    async cancelOrder(orderId) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to Cancel the Order!",
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Yes, Cancel Order!",
        cancelButtonText: "No don't Cancel!",
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then(async (result) => {
        if (result.value) {
          this.$swal({
            timer: 1100,
            title: "Cancelled!",
            text: "Your Order has been Cancelled.",
          });
          this.$store.dispatch("profile/fetchCancelOrderData", orderId);
        }
      });
    },
    async orderAgain() {
      const result = this.orderDetailsData.items.map((each) => {
        return {
          _id: each.productId,
          imageLink: each.imageLink,
          description_summary: each.name,
          qty: each.qty,
          Price: each.price,
          providerName: each.providerName,
        };
      });
      try {
        result.map((each) => {
          if (this.cartData.length > 0) {
            if (
              this.cartData[0].providerName ==
              this.orderDetailsData.items[0].providerName
            ) {
              const list = [];
              list.push(each);
              list.map((each) => {
                this.$store.dispatch("checkout/increaseCartQty", {
                  cartItem: each,
                  callApi: this.callCheckout,
                });
                this.loading = false;
                if (this.callCheckout)
                  this.$store.dispatch("checkout/checkoutFinal");
              });

              this.$swal({
                title: "Added To Cart",
                text: "Please proceed with your order.",
                imageUrl: "img/cart-popup.jpg",
                imageHeight: 80,
                imageWidth: 80,
                timer: 1100,
              });

              setTimeout(() => {
                this.$router.push("/checkout/");
              }, 1500);
            } else {
              this.$swal({
                title: `Sure??`,
                text: "You have product from different seller in your cart.Do you want to remove it and add this item?",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Yes",
              }).then(async (res) => {
                if (res.value) {
                  await this.$store.dispatch("checkout/clearCart");
                  result.map((each) => {
                    this.$store.dispatch("checkout/increaseCartQty", {
                      cartItem: each,
                      callApi: this.callCheckout,
                    });
                    if (this.callCheckout)
                      this.$store.dispatch("checkout/checkoutFinal");
                  });

                  this.$swal({
                    title: "Added To Cart",
                    text: "Please proceed with your order.",
                    imageUrl: "img/cart-popup.jpg",
                    imageHeight: 80,
                    imageWidth: 80,
                    timer: 1100,
                  });

                  setTimeout(() => {
                    this.$router.push("/checkout/");
                  }, 1500);
                }
                this.loading = false;
              });
            }
          } else {
            this.$store.dispatch("checkout/increaseCartQty", {
              cartItem: each,
              callApi: this.callCheckout,
            });
            this.loading = false;
            if (this.callCheckout)
              this.$store.dispatch("checkout/checkoutFinal");
            this.$swal({
              title: "Added To Cart",
              text: "Please proceed with your order.",
              imageUrl: "img/cart-popup.jpg",
              imageHeight: 80,
              imageWidth: 80,
              timer: 1100,
            });
            setTimeout(() => {
              this.$router.push("/checkout/");
            }, 1500);
          }
        });
      } catch {
        this.loading = false;
      }
      await this.$store.dispatch("checkout/fetchCartData", {
        shipFrom: "IN",
        shipTo: "IN",
        country: "IN",
      });
    },

    getContactDetails(e) {
      try {
        if (this.orderDetailsData.deliveryAddress.address.contacts) {
          for (let element of this.orderDetailsData.deliveryAddress.address
            .contacts) {
            if (element.contactType == e) {
              return element.value;
            }
          }

          return "";
        } else {
          return "";
        }
      } catch {
        return "";
      }
    },
    orderStatus(e) {
      switch (e) {
        case "Created":
          return 0;
        case "Processing":
          return 1;
        case "Dispatched":
          return 2;
        case "Delivered":
          return 3;
        default:
          return -1;
      }
    },
    needHelpClick(orderId) {
      this.$store.dispatch("profile/getOrderDetailsData", orderId);
      this.$router.push({
        name: "need-help-orderId",
        params: { orderId: orderId },
      });
    },
  },
  mounted() {},
};
</script>

<style scoped>
.cancelbg {
  background-color: #ff3c3c !important;
}
.od-ulli .media-aside {
  width: 150px;
}
.swal-imageUrl {
  width: 200px;
  height: 200px;
}
.od-ulli li {
  margin-bottom: 6px;
  margin-top: 6px;
}
.profile-maindv {
  background: #f8f8f8;
  padding: 15px;
  border-radius: 15px;
  border: 1px solid #efefef;
}
.edit-icon svg {
  margin-right: 7px;
  margin-top: 4px;
}

.order-maindiv {
  background: #f8f8f8;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  border: 1px solid #efefef;
}
.status-xsbutton {
  background: #04c800;
  color: #fff;
  padding: 2px 7px 2px 7px;
  font-size: 14px;
}
.cancelled-button {
  background: #c83500;
  color: #fff;
  padding: 2px 7px 2px 7px;
  font-size: 14px;
}
.xsbutton:hover {
  text-decoration: none;
  color: #fff;
  background: #07ac04;
}

.steps .step {
  display: block;
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
  z-index: 1;
}

.steps .step .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  text-align: center;
}

.steps .step .step-icon-wrap::before,
.steps .step .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
}

.steps .step .step-icon-wrap::before {
  left: 0;
}

.steps .step .step-icon-wrap::after {
  right: 0;
}

.steps .step .step-icon {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 20px;
  line-height: 37px;
  z-index: 5;
}

.steps .step .step-title {
  margin-top: 16px;
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}

.steps .step:first-child .step-icon-wrap::before {
  display: none;
}

.steps .step:last-child .step-icon-wrap::after {
  display: none;
}

.steps .step.completed .step-icon-wrap::before,
.steps .step.completed .step-icon-wrap::after {
  background-color: #04c800;
}

.steps .step.completed .step-icon {
  border-color: #04c800;
  background-color: #04c800;
  color: #fff;
}

@media (max-width: 576px) {
  .flex-sm-nowrap .step .step-icon-wrap::before,
  .flex-sm-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .step .step-icon-wrap::before,
  .flex-md-nowrap .step .step-icon-wrap::after {
    display: none;
  }
  .steps .step {
    width: 17%;
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .step .step-icon-wrap::before,
  .flex-lg-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .step .step-icon-wrap::before,
  .flex-xl-nowrap .step .step-icon-wrap::after {
    display: none;
  }
}

.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}
</style>

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return Object.keys(_vm.orderDetailsData).length != 0 ? _c('div', {
    staticClass: "profile mb-4"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-left mb-3"
  }, [_c('h5', [_vm._v("Order Details")])]), _c('div', {
    staticClass: "d-flex profile-maindv mb-4"
  }, [_c('div', {
    staticClass: "profile-detail mr-2 ml-2"
  }, [_c('h6', {
    staticClass: "mb-3"
  }, [_vm._v("Delivery Address")]), _c('b-media', {
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_vm._v("Name:")];
      },
      proxy: true
    }], null, false, 781403834)
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.fullName : "") + " ")])]), _c('p', {
    staticClass: "mb-2"
  }, [_c('b-icon', {
    attrs: {
      "icon": "telephone-fill",
      "aria-label": "Help"
    }
  }), _vm._v(" +91 " + _vm._s(_vm.getContactDetails("PRIMARY_PHONE")) + " ")], 1), _c('p', {
    staticClass: "mb-2"
  }, [_c('b-icon', {
    attrs: {
      "icon": "envelope-fill",
      "aria-label": "Help"
    }
  }), _vm._v(" " + _vm._s(_vm.getContactDetails("PRIMARY_EMAIL")) + " ")], 1), _c('p', {
    staticClass: "mb-2"
  }, [_c('b-icon', {
    attrs: {
      "icon": "geo-alt-fill",
      "variant": "success",
      "aria-label": "Help"
    }
  }), _vm._v(" " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.houseNumber : "") + ", " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.street2 : "") + ", " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.city : "") + ", " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.state : "") + ", " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.country : "") + ", " + _vm._s(_vm.orderDetailsData && _vm.orderDetailsData.deliveryAddress && _vm.orderDetailsData.deliveryAddress.address ? _vm.orderDetailsData.deliveryAddress.address.zip : "") + " ")], 1)], 1)]), _c('div', {
    staticClass: "d-flex profile-maindv"
  }, [_c('div', {
    staticClass: "profile-detail mr-2 ml-2"
  }, [_c('h6', {
    staticClass: "mb-3"
  }, [_vm._v("Order Details")]), _c('ul', {
    staticClass: "list-unstyled od-ulli mb-0"
  }, [_c('b-media', {
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_vm._v("Provider:")];
      },
      proxy: true
    }], null, false, 2198722940)
  }, [_c('div', [_c('p', {
    staticClass: "mb-0 font-weight-bold"
  }, [_vm._v(_vm._s(_vm.providerName))])])]), _c('b-media', {
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_vm._v("Order Number:")];
      },
      proxy: true
    }], null, false, 2253132368)
  }, [_c('p', {
    staticClass: "mb-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.orderId : "") + " ")])]), _c('b-media', {
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_vm._v("Order Created Date:")];
      },
      proxy: true
    }], null, false, 2428819239)
  }, [_c('p', {
    staticClass: "mb-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.createdDate) + " ")])]), _c('b-media', {
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_vm._v("Amount:")];
      },
      proxy: true
    }], null, false, 2932159633)
  }, [_c('p', {
    staticClass: "mb-0 font-weight-bold"
  }, [_vm._v(" ₹ " + _vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.orderAmount : "") + " ")])]), _c('b-media', {
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_vm._v("Payment Mode:")];
      },
      proxy: true
    }], null, false, 658463876)
  }, [_c('p', {
    staticClass: "mb-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.paymentType : "") + " ")])]), _c('b-media', {
    attrs: {
      "tag": "li"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_vm._v("Update Order Status:")];
      },
      proxy: true
    }], null, false, 1870641846)
  }, [_c('p', {
    staticClass: "mb-0 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.reportStatus : "") + " ")])])], 1)])])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "text-left mb-3"
  }, [_c('h5', [_vm._v("Track Order")])]), _vm.orderDetailsData.orderStatus !== 'Cancelled' ? _c('div', {
    staticClass: "steps d-flex flex-wrap flex-sm-nowrap justify-content-between"
  }, [_c('div', {
    class: "step ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 0 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "step-icon-wrap"
  }, [_c('div', {
    staticClass: "step-icon"
  }, [_c('b-icon', {
    attrs: {
      "icon": "check",
      "aria-label": "check"
    }
  })], 1)]), _c('h4', {
    staticClass: "step-title"
  }, [_vm._v("Order")])]), _c('div', {
    class: "step ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 1 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "step-icon-wrap"
  }, [_c('div', {
    staticClass: "step-icon"
  }, [_c('b-icon', {
    attrs: {
      "icon": "gear",
      "aria-label": "gear"
    }
  })], 1)]), _c('h4', {
    staticClass: "step-title"
  }, [_vm._v("Under Process")])]), _c('div', {
    class: "step ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 2 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "step-icon-wrap"
  }, [_c('div', {
    staticClass: "step-icon"
  }, [_c('b-icon', {
    attrs: {
      "icon": "truck",
      "aria-label": "truck"
    }
  })], 1)]), _c('h4', {
    staticClass: "step-title"
  }, [_vm._v("Shipped")])]), _c('div', {
    class: "step ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 3 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "step-icon-wrap"
  }, [_c('div', {
    staticClass: "step-icon"
  }, [_c('b-icon', {
    attrs: {
      "icon": "house",
      "aria-label": "house"
    }
  })], 1)]), _c('h4', {
    staticClass: "step-title"
  }, [_vm._v("Delivered")])])]) : _c('div', {
    staticClass: "steps d-flex flex-wrap flex-sm-nowrap justify-content-between"
  }, [_c('div', {
    class: "step ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 0 ? 'completed' : '')
  }, [_c('div', {
    staticClass: "step-icon-wrap"
  }, [_c('div', {
    staticClass: "step-icon"
  }, [_c('b-icon', {
    attrs: {
      "icon": "check",
      "aria-label": "check"
    }
  })], 1)]), _c('h4', {
    staticClass: "step-title"
  }, [_vm._v("Order")])]), _c('div', {
    class: "step ".concat(_vm.orderStatus(_vm.orderDetailsData.orderStatus) >= 1 ? 'Cancelled' : '')
  }, [_c('div', {
    staticClass: "step-icon-wrap"
  }, [_c('div', {
    staticClass: "step-icon cancelbg"
  }, [_c('b-icon', {
    staticClass: "text-white",
    attrs: {
      "icon": "x-circle",
      "aria-label": "x-circle"
    }
  })], 1)]), _c('h4', {
    staticClass: "step-title"
  }, [_vm._v("Cancelled")])])]), _c('div', {
    staticClass: "order-maindiv"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between mb-4"
  }, [_c('div', [_c('h6', [_vm._v("#" + _vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.orderId : ""))]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-link', {
    class: _vm.orderDetailsData.orderStatus === 'Cancelled' ? 'cancelled-button' : 'xsbutton' || _vm.orderDetailsData.orderStatus === 'Created' ? 'status-xsbutton' : 'xsbutton',
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.orderStatus : ""))])], 1)]), _c('div', [_c('p', {
    staticClass: "m-0",
    style: {
      color: '#58585b'
    }
  }, [_vm._v(" Order Total (" + _vm._s(Array.isArray(_vm.orderDetailsData.items) && _vm.orderDetailsData.items.length) + " item) ")]), _c('h4', [_vm._v(" ₹ " + _vm._s(_vm.orderDetailsData ? _vm.orderDetailsData.orderAmount : "") + " ")])])]), _vm._l(_vm.orderDetailsData.items, function (item, index) {
    return _c('ul', {
      key: index,
      staticClass: "list-unstyled"
    }, [_vm.$options.components['items-order-item'] ? [_c('items-order-item', {
      attrs: {
        "itemData": item
      }
    })] : _vm._e()], 2);
  })], 2), _c('div', {
    staticClass: "primary-btn text-right"
  }, [(_vm.orderDetailsData.orderStatus === 'Processing' || _vm.orderDetailsData.orderStatus === 'Dispatched' || _vm.orderDetailsData.orderStatus === 'Delivered' || _vm.orderDetailsData.orderStatus === 'Cancelled' ? true : false) ? _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.orderAgain
    }
  }, [_vm._v("Order Again")]) : _c('b-button', {
    staticClass: "mr-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.cancelOrder(_vm.orderDetailsData.orderId);
      }
    }
  }, [_vm._v("Cancel Order")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.needHelpClick(_vm.orderDetailsData.orderId);
      }
    }
  }, [_vm._v("Need Help")])], 1)])], 1)], 1)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }